import * as React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../design-system/theme';
import SvgBackArrow from '../../design-system/icons/js/BackArrow';

export default class CaseContent extends React.PureComponent {
	render() {
		const { html, content } = this.props;
		let tags = [content.year, content.client, ...(content.categories || [])];
		if (content.tags && content.tags.length > 0 && content.tags[0]) {
			tags = [...tags, ...content.tags];
		}
		return (
			<StyledCaseContent>
				<header>
					<Link to="/">
						<div>
							<SvgBackArrow />
						</div>
					</Link>
					<div className="facts">
						<div>
							<strong>{content.title}</strong>
						</div>
						<div className="tags">
							{tags.map((tag, index) => (
								<React.Fragment key={tag}>
									<span>{tag}</span>
									{index < tags.length - 1 ? ', ' : ''}
								</React.Fragment>
							))}
						</div>
					</div>

					<div className="teaser-image">
						<svg viewBox="0 0 100 100">
							<g />
						</svg>
						<img src={content.teaserImage} alt="" />
					</div>
				</header>
				<div className="content" dangerouslySetInnerHTML={{ __html: html }} />
			</StyledCaseContent>
		);
	}
}

CaseContent.propTypes = {
	html: PropTypes.string,
	content: PropTypes.shape({
		title: PropTypes.string,
		year: PropTypes.string,
		slug: PropTypes.string,
		client: PropTypes.string,
		categories: PropTypes.arrayOf(PropTypes.string),
		tags: PropTypes.arrayOf(PropTypes.string),
		teaserImage: PropTypes.string
	}).isRequired
};

const StyledCaseContent = styled.div`
	> header {
		box-sizing: border-box;
		max-width: ${theme.dimensions.contentWidthSmall};
		padding: 27px 0 0 0;
		margin: 0 auto;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			max-width: ${theme.dimensions.contentWidthLarge};
		}

		> a {
			display: block;
			font-size: 0;

			> div {
				display: inline-block;
				position: relative;

				&::before {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					width: 40px;
					height: 40px;
					background-color: rgba(255, 0, 0, 0);
					transform: translate(-50%, -50%);
				}
			}

			svg {
				display: block;
				width: 24px;
				height: 24px;
				margin-left: -3px;
				color: ${theme.colors.icon};
			}
		}

		.facts {
			box-sizing: border-box;
			margin-top: 12px;
			padding: 10px 0 0 0;
			border-top: 1px solid ${theme.colors.line};

			.tags {
				> span {
					white-space: nowrap;
				}
			}
		}

		.teaser-image {
			margin-top: 20px;
			position: relative;
			font-size: 0;
			border-radius: 4px;
			overflow: hidden;

			@media (min-width: ${theme.breakpoints.tablet}px) {
				margin-top: 30px;
			}

			> svg {
				width: 100%;
			}

			> img {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	> .content {
		max-width: ${theme.dimensions.contentWidthSmall};
		margin: 0 auto;
		text-align: center;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			max-width: ${theme.dimensions.contentWidthLarge};
			text-align: left;
		}

		h2 {
			margin: 16px 0 0 0;
		}

		img {
			display: block;
			width: 100%;
			margin-top: 40px;
			border-radius: 4px;
			overflow: hidden;

			@media (min-width: ${theme.breakpoints.tablet}px) {
				margin-top: 60px;
			}
		}
	}
`;
