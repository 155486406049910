import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SEO from '../seo';
import Layout from '../Layout';
import Footer from '../Footer';
import CaseContent from './CaseContent';
import CmsRedirect from '../CmsRedirect';

export default class CasePage extends React.PureComponent {
	render() {
		const { html, content, footerContent, commonContent } = this.props;
		return (
			<StyledCasePage>
				<SEO title={`The New Radiance / ${content.title}`} />
				<CmsRedirect caseSlug={content.slug} />
				<CaseContent content={content} html={html} />
				<Footer content={footerContent} commonContent={commonContent} />
			</StyledCasePage>
		);
	}
}

CasePage.propTypes = {
	html: PropTypes.string,
	content: PropTypes.object.isRequired,
	footerContent: PropTypes.shape({
		getInTouchButton: PropTypes.string.isRequired,
		html: PropTypes.string.isRequired
	}).isRequired,
	commonContent: PropTypes.shape({
		backToTop: PropTypes.string.isRequired
	}).isRequired
};

const StyledCasePage = styled(Layout)`
	padding-bottom: 100px;
`;
