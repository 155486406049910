import * as React from 'react';
import { graphql } from 'gatsby';
import CasePage from '../components/case-page/CasePage';

const CasePageContainer = props => {
	const {
		data: {
			pageContent: {
				frontmatter: pageFrontmatter,
				html,
				fields: { slug }
			},
			footerContent: {
				childMarkdownRemark: { html: footerHtml, frontmatter: footerFrontmatter }
			},
			commonContent: {
				childMarkdownRemark: { frontmatter: commonFrontmatter }
			}
		}
	} = props;
	return (
		<CasePage
			content={{ ...pageFrontmatter, slug }}
			html={html}
			footerContent={{ ...footerFrontmatter, html: footerHtml }}
			commonContent={commonFrontmatter}
		/>
	);
};

export const pageQuery = graphql`
	query CasePageQuery($slug: String!) {
		pageContent: markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				year
				client
				categories
				tags
				teaserImage
			}
			fields {
				slug
			}
		}
		footerContent: file(sourceInstanceName: { eq: "pages" }, name: { eq: "footer" }) {
			childMarkdownRemark {
				html
				frontmatter {
					getInTouchButton
				}
			}
		}
		commonContent: file(sourceInstanceName: { eq: "pages" }, name: { eq: "common" }) {
			childMarkdownRemark {
				frontmatter {
					backToTop
				}
			}
		}
	}
`;

export default CasePageContainer;
